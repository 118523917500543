import { useSelector } from "react-redux";
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/light.css";

const LeftContent = (props) =>{
    
    const teams = useSelector(state => state.teams)

    const bpClickHandler = (table)=>{
        props.changeTable(table);
    }

    return <div className="leftContent">
            <div id="leftLinkWrapper">
                <div className={"leftLink " + (props.selectedTable==="batters" ? 'selectedLink' : '')}  onClick={bpClickHandler.bind(null, 'batters')}>Batters</div>
                <div className={"leftLink " + (props.selectedTable==="pitchers" ? 'selectedLink' : '')} onClick={bpClickHandler.bind(null, 'pitchers')}>Pitchers</div>
                <div id="teamWrapper">
                    <select name="teamsSelect" id="teamSelect" className="leftLink" onChange={props.changeTeamHandler}>
                        <option value="ALL">All Teams</option>
                        {teams.map(team=>{
                            return <option key={team.short} value={team.short}>{team.long}</option>
                        })}
                    </select>
                </div>
                <div id='dateWrapper' className="customDatePickerWidth">
                    <Flatpickr options={{inline: 'true'}} defaultValue={props.boxDate.toISOString()} onChange={props.changeDate}/>
                </div>
            </div>
        </div>
}

export default LeftContent;