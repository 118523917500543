import { createStore } from "redux";

const initial = { batters: [], pitchers: [], teams:[] };

const getFieldName = header =>{
    switch (header){
        case "AB":
            return "atBats"; 
        case "R":
            return "runs"; 
        case "H":
            return "hits"; 
        case "BB":
            return "baseOnBalls"; 
        case "SO":
            return "strikeOuts"; 
        case "SB":
            return "stolenBases"; 
        case "2B": 
            return "doubles"; 
        case "3B": 
            return "triples"; 
        case "HR":
            return "homeRuns"; 
        case "AVG":
            return "s_avg";
        case "HRs":
            return "s_homeRuns"; 
        case "Rs":
            return "s_runs"; 
        case "RBIs":
            return "s_rbi"; 
        case "OBP":
            return "s_obp"; 
        case "SLG":
            return "s_slg"; 
        case "Ks":
            return "s_strikeOuts"; 
        case "IP":
            return "inningsPitched"; 
        case "ER":
            return "earnedRuns"; ;
        case "ERA":
            return "s_era"; 
        case "IPs":
            return "s_inningsPitched"; 
        case "W":
            return "s_wins"; 
        case "SV":
            return "s_saves"; 
        case "HLD":
            return "s_holds"; 
        default:
            return header.toLowerCase();
    }
}

const nameSort = (a, b)=>{
    var a_name = a.name.split(" ");
    a_name = a_name[1] + a_name[0];	//REVERSE LASTNAME-FIRSTNAME
    
    var b_name = b.name.split(" ");
    b_name = b_name[1] + b_name[0];
    
    return (a_name < b_name ? -1: a_name > b_name ? 1: 0 );
}

const propSort = ({header, bORp}) =>{			//http://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value-in-javascript
    const battingPitching = bORp === "b" ? "batting" : "pitching";
    let field = getFieldName(header);
    let stats = "";
    
    if (field.startsWith("s_")){
        field = field.substr(2);
        stats = "seasonStats";
    }else{
        stats = "stats";
    }

    if (field=="name") {
        return nameSort
    }else if (field=="team"){		//text sort
        return function (a, b) {
            return (a[field] < b[field] ? -1: a[field] > b[field] ? 1:  nameSort(a, b));		//ASCENDING ORDER, IF TIE SORT BY NAME
        }
    } else{									//numerical sort
        return function (a, b) {
            return (b[stats][battingPitching][field] - a[stats][battingPitching][field] !=0 ? b[stats][battingPitching][field] - a[stats][battingPitching][field] : nameSort(a, b));		//DESCENDING ORDER, IF TIE SORT BY NAME
        }
    }
};

const playerReducer = (state = initial, action) =>{

    if (action.type == "sort"){
        const battersPitchers = action.sortData.bORp == "b" ? "batters" : "pitchers";
        return ({...state, [battersPitchers] : state[battersPitchers].sort(propSort(action.sortData))});
    }else if (action.type=='update'){
        return {'teams': action.data.teams.sort((a,b) => a.long.localeCompare(b.long)), 'batters': action.data.batters.sort(nameSort), 'pitchers': action.data.pitchers.sort(nameSort)};
    }else{
        return state;   //default
    }
    
}

const store = createStore(playerReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
