
const Navbar  = (props)=>{

    return <div id='navbar'>
        {props.sections.map( section => {
            return <div className="navItem" key={`nav${section.title}`} onClick={props.clickHandler}>{section.title}</div>
        })}
    </div>
}

export default Navbar;