import Table from "./Table";
import { useEffect, useState } from "react";
import LeftContent from "./LeftContent";
import {  useDispatch } from "react-redux";
import players080222 from '../data/players080222.js';

let currYear = new Date().getFullYear();
const START_OF_SEASON = new Date(currYear, 2, 1);
const END_OF_SEASON = new Date(currYear, 9, 1);
const END_OF_LAST_SEASON = new Date(currYear-1, 9, 1);

const getGameData = (data) => {
  const players = { batters: {}, pitchers: {}, teams:[] };

  for (let i = 0; i <= 1; i++) {
    //ONLY TAKE FROM THE PLAYERS OBJECT THOSE IDS THAT APPEAR IN BATTERS/PITCHERS ARRAYS, AND ADD TEAM NAME
    let homeAway = i == 0 ? "home" : "away";
    players.teams.push({
      short: data.teams[homeAway].team.abbreviation,
      long: data.teams[homeAway].team.shortName,
    });

    for (let j = 0; j <= 1; j++) {
      let battersPitchers = j == 0 ? "batters" : "pitchers";

      for (let k = 0; k < data.teams[homeAway][battersPitchers].length; k++) {
        let player = data.teams[homeAway].players["ID" + data.teams[homeAway][battersPitchers][k]];
        if (battersPitchers === "batters" && player.position.abbreviation === "P") continue;
        player.team = data.teams[homeAway].team.abbreviation;
        player.name = player.person.fullName;
        //players[battersPitchers].push(player);
        players[battersPitchers][player.person.id] = player;
      }
    }
  }

  return players;
};


const Baseball = ()=>{
    let defaultDate = new Date();
    if (defaultDate < START_OF_SEASON){
      defaultDate = END_OF_LAST_SEASON;
    }else if (defaultDate > END_OF_SEASON){
      defaultDate = END_OF_SEASON;
    }
  
    const [loading, setLoading] = useState(null);
    const [showTable, setShowTable] = useState('batters');
    const [showTeam, setShowTeam] = useState('ALL')
    const [showDate, setShowDate] = useState(defaultDate);
    const [errMsg, setErrMsg] = useState(null);

    const dispatch = useDispatch();

    const changeTable = table => {
        setShowTable(table);
    }

    const changeTeam = e =>{
      setShowTeam(e.target.value);
    }

    const fpChangeDate = (dateArr, dateStr)=>{  //flatpickr callback
      console.log(dateArr[0]);
      setShowDate(dateArr[0]);
    }

    useEffect(() => {
      document.title = 'tony845';
      const link = document.querySelector("link[rel='icon']");
        if (link != null){
            link.href = "/baseball.png";
        }
    }, []);

    useEffect(() => {
        const fetchPlayerData = async () => {
            setLoading(true);  
            setErrMsg(null);
            const urlDate = `${showDate.getMonth()+1}/${showDate.getDate()}/${showDate.getFullYear()}`;
            const scheduleURL ="https://statsapi.mlb.com/api/v1/schedule?sportId=1&date=" + urlDate + "&fields=dates,games,gamePk,status,codedGameState";
            
            try{
              const scheduleResponse = await fetch(scheduleURL);
              if (!scheduleResponse.ok){
                throw new Error('Something went wrong fetching data');
              }
              const scheduleData = await scheduleResponse.json();

              if (scheduleData.dates.length==0){
                throw new Error('No games scheduled for selected date');
              }
            

              const gameFetches = [];
              const playersByGame = [];
              scheduleData.dates[0].games.forEach((element) => {
                  console.log(element.gamePk);
                  gameFetches.push(
                    fetch(`https://statsapi.mlb.com/api/v1/game/${element.gamePk}/boxscore`).then( async (response) => {
                        console.log("test");
                        if (!response.ok) throw new Error('Bad response from boxscore fetch');
                        const data = await response.json();
                        playersByGame.push(getGameData(data));
                    })
                  );
              });

              await Promise.all(gameFetches);

              //const playersByGame = JSON.parse(JSON.stringify(players080222));  //deep copy
              const gamesMap = new Map();
              playersByGame.forEach(game=>{
                let key = game.teams[0].short + "-" + game.teams[1].short;

                if (!gamesMap.has(key)){
                  gamesMap.set(key, game);
                }else{    //account for double headers
                  let firstGame = gamesMap.get(key);
                  for (let p = 1; p<=2; p++){
                    const battersPitchers = p==1 ? 'batters' : 'pitchers';
                    const battingPitching = p==1 ? 'batting' : 'pitching';
                    const fields = p==1 ? ["atBats", "runs", "hits", "baseOnBalls", "strikeOuts", "stolenBases", "doubles","triples","homeRuns"] : ["inningsPitched", "earnedRuns"];

                    for (const id in game[battersPitchers]){
                      if (!firstGame[battersPitchers][id]){
                        firstGame[battersPitchers][id] = game[battersPitchers][id];
                      }else{
                        fields.forEach(field=>{
                          firstGame[battersPitchers][id].stats[battingPitching][field] += game[battersPitchers][id].stats[battingPitching][field];
                        })
                      }
                    }
                  }
                }
              });

              const players = [...gamesMap.values()].reduce( (final, curr) =>{
                  for (let arr in final){
                    final[arr] = final[arr].concat(Object.values(curr[arr]));
                  }
                  
                  return final;
              },{ batters: [], pitchers: [], teams:[] });


              //dispatch({type: 'update', data: {batters, pitchers, teams}})
              dispatch({type: 'update', data: players})
            }catch(err){
              setErrMsg(err.message);
            }

            setLoading(false);
        };
        
        sessionStorage.setItem('bSortHeading', "");
        sessionStorage.setItem('pSortHeading', "");
        fetchPlayerData();
        

    }, [showDate]);

    return (
        <div id='baseballWrapper'>
            <LeftContent changeTable={changeTable} selectedTable={showTable} changeTeamHandler={changeTeam} boxDate={showDate} changeDate={fpChangeDate}/>
            {!loading && !errMsg && showTable==="batters" && <Table id="batters" showTeam={showTeam}/>}
            {!loading && !errMsg && showTable==="pitchers" && <Table id="pitchers" showTeam={showTeam} showDate={showDate}/>}
            {loading && !errMsg && <p>Loading...</p>}
            {!loading && errMsg && <p className="errText">{errMsg}</p>}
        </div>
    );
}

export default Baseball;
