import { useDispatch } from "react-redux";

const TableHeadings = (props)=>{
    const dispatch = useDispatch();

    const sortHandler = e =>{
        sessionStorage.setItem(props.bORp + "SortHeading", e.target.innerHTML);     //store in browser session
        dispatch({type:'sort', sortData:{header: e.target.innerHTML, bORp: props.bORp} });
    }

    return <thead>
        <tr>
            {props.headings.map(heading => {
                let classes = heading == sessionStorage.getItem(props.bORp + "SortHeading") ? 'sortedHeading' : "";
                if (heading === "Logs") classes += " noSort";
                return <th key={props.bORp + heading} onClick={heading !== "Logs" ? sortHandler : ()=>{}} className={classes}>{heading}</th>
            }) }
        </tr>
    </thead>

}

export default TableHeadings;