import { useEffect, useState } from "react";

const getInningStarted = (json, teamCode, playerID) =>{	
    let team = json.teams.away.team.fileCode == teamCode ? json.teams.away : json.teams.home;
    let gameInning = 0;
    let gameOuts = 0;
    let gameNote = "";
    
    for(let i = 0; i < team.pitchers.length; i++){
        const id = team.pitchers[i];
        const pitcher = team.players[`ID${id}`];
        
        if (id === playerID) {
            gameNote = pitcher.stats.pitching.note ? pitcher.stats.pitching.note : "";
            if (gameOuts==0) gameInning++;  //pitcher started the next inning clean
            break;
        }

        const [innings, outs]  = pitcher.stats.pitching.inningsPitched.split(".");
        gameInning += parseInt(innings);
        gameOuts += parseInt(outs);
        if (gameOuts > 2){
            gameInning++;
            gameOuts = gameOuts % 3;
        }
        
    }
    //console.log(`${date}: ${gameInning}`);
    return {inning: gameInning, note: gameNote};
	
};

const SideTable = (props)=>{
    const [loading, setLoading] = useState(false);
    const [inningLog, setInningLog] = useState([]);
    const [errMsg, setErrMsg] = useState(null);

    useEffect(()=>{
        const fetchGameHistory = async ()=>{
            setLoading(true);
            setErrMsg(null);
            const playerID = props.playerID;  //476595;
            const url = `https://statsapi.mlb.com/api/v1/people/${playerID}/stats?stats=gameLog&leagueListId=mlb_hist&group=pitching&gameType=R&sitCodes=1,2,3,4,5,6,7,8,9,10,11,12&hydrate=team&season=${props.year}&language=en`;
            const gameFetches = [];
            const boxScoreInfo = [];

            try{
                const gamesResponse = await fetch(url);
                if (!gamesResponse.ok){
                    throw new Error('Something went wrong fetching game data');
                }
                const gamesData = await gamesResponse.json();

                if (gamesData.stats.length==0){
                    throw new Error('No games returned for pitcher');
                }
                gamesData.stats[0].splits.forEach(game=>{	
                    const gameUrl = "https://statsapi.mlb.com/api/v1/game/" + game.game.gamePk + "/boxscore";
                    const team = game.team.fileCode;
                    //const date = game.date;
                    const [year, month, day] = game.date.split("-");
                    const date = new Date(year, month-1, day);

                    gameFetches.push(fetch(gameUrl).then(async (response)=>{
                        if (!response.ok) throw new Error('Bad response from boxscore fetch');
                         const data = await response.json();            
                         const {inning, note} = getInningStarted(data, team, playerID);  
                         boxScoreInfo.push({date, inning, note});
                    }))
                });

                await Promise.all(gameFetches);

                boxScoreInfo.sort((a,b)=>a.date - b.date);
                setInningLog(boxScoreInfo);

            }catch(err){
                setErrMsg(err.message);
            }
            setLoading(false);
        }

        fetchGameHistory();

    },[props.playerID]);


    return <>
        <table className="baseballTable sideTable">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Inn Start</th>
                    <th>Note</th>
                </tr>
            </thead>
            <tbody>
                {loading && !errMsg && <tr><td>Loading...</td></tr>}
                {!loading && errMsg && <tr><td className="errText">{errMsg}</td></tr>}
                {!loading && !errMsg && inningLog.map(game=>{
                    return <tr className="playerRow" key={game.date}>
                        <td>{new Date(game.date).toLocaleDateString()}</td>
                        <td>{game.inning}</td>
                        <td>{game.note}</td>
                    </tr>
                })}
            </tbody>

        </table>
        
    </>
}

export default SideTable