
export const heading = "Application Developer with over 15 years of experience designing, coding, and troubleshooting software, with the last four years having a focus on web applications.  Possess a BS in Computer Science from Rutgers University.";

//export const languages = ['JavaScript, jQuery, & KnockoutJS', 'Java', 'Visual Basic (VB)', 'HTML5 & CSS'];

export const sections = [{title:'Languages', heading:'Programming Languages & Libraries', data:['JavaScript including React, jQuery, & KnockoutJS', 'Java', 'Visual Basic (VB)', 'HTML5 & CSS']}, 
    {title: 'Skills', heading: 'Summary of Skills and Qualifications', 
        data: ["Proficient in coding web apps in Eclipse and Visual Studio IDE's", 
            "RESTful API maintenance and configuration", 
            "Experience with asynchronous request handling (Ajax) utilizing JSON",
            "DOM manipulation via jQuery",
            "Personal experience with React and Node.js",
            "Debug client-side web page issues through browser Developer Tools console",
            "Query databases using SQL to verify results and identify test cases",
            "Manage code in Subversion and Git version control software",
            "Participate in regular team meetings to update project status and discuss any obstacles",
            "Contribute to initial design plans and provide project time estimates",
            "Experience collaborating with multiple teams, including offshore, to implement new product features"
        ]
    },
    {title: 'Employment', heading:'Employment History', 
        data:[{title: 'Application Developer & Support Engineer', company: 'Conduent', start: 'Sep 2005', end: 'present', id:'CND',
                description: 'Designed and programmed calc framework for new software platform, ensuring system was modular and dynamic for various client needs.  Worked on several client implementations from design through production release phases.  Recently helped transform software from standalone Java application to having a web front end.  Also contributed to implementation of new single page application (SPA) web product on .NET platform.',
                highlights: ['Improved calc processing time of a client calc and two data extracts by 90% each',
                    'Added ability to run 50+ calcs at once on top of a framework that was originally designed for just one at a time',
                    'Streamlined code base of a project that was hitting the system memory limit',
                    'Designed and automated a new output standard to avoid hitting file size limits'
                ]
            },
            {title: 'QA Analyst', company: 'AON Hewitt', start: 'May 2004', end: 'Sep 2005', id:'HW',
                description: 'Wrote, maintained, and executed QA test plans.  Queried for and/or mocked up test cases to cover all possible scenarios.  Documented and reported any issues to the programming team, also researched and suggested potential fixes.  Ran regression tests before each production move.',
                highlights: ['Identified an error in an annual rollup process that was resulting in incorrect payments for years']
            },
            {title: 'Senior Research Analyst', company: 'Dow Jones Indexes', start: 'Aug 2000', end: 'May 2004', id:'DJ',
                description: 'Involved with developmental stages of new stock indexes.  Wrote Visual FoxPro macros to create historical index composition based on desired methodology.  Ran queries to check data quality, correct any bad data points, and provide marketing materials for new product launches.  Oversaw handoff of completed indexes to the production team for ongoing maintenance.',
                highlights: ['Created a dynamic Excel template file to replace the previously manual index review process',
                    'Created automated monthly reports for quarterly distributions which allowed for a quicker handoff from development to production team'
                ]
            }

            ]
    }];