import { useState, useRef, useEffect } from 'react';
import './Resume.css';
import Navbar from './Navbar';
import { sections, heading } from '../../data/resumeData';
import Sections from './Sections';
import { Link } from 'react-router-dom';

const Resume = ()=>{
    const [showSection, setShowSection] = useState("all");
    const sectionRef = useRef(null);

    useEffect(() => {
        document.title = 'Tony Attanasio';
        const link = document.querySelector("link[rel='icon']");
        if (link != null){
            link.href = "/resume.png";
        }
      }, []);

    const navClickHandler = e=>{
        if (sectionRef.current !== null){
            sectionRef.current.classList.remove('navSelected');
            if (sectionRef.current === e.target){
                setShowSection('ALL');
                sectionRef.current = null;
                return;
            }
        }
        sectionRef.current = e.target;
        e.target.classList.add('navSelected')
        setShowSection(e.target.innerHTML);
    }
    
    
    return <div id='resumeWrapper'>
        <div id='resumeHeader'>
            <p style={{lineHeight: '1.75em'}}><span id='myName'>Tony Attanasio</span>
            <br/>
            <a href="mailto:tony845@tony845.com">tony845@tony845.com</a> &nbsp; | &nbsp; <a href="http://www.linkedin.com/in/tony845" target="_blank">LinkedIn</a>
            &nbsp; | &nbsp; <Link to="/">tony845.com</Link></p>
        </div>
        <hr/>
        <p id='summary'>{heading}</p>
        <Navbar clickHandler={navClickHandler} sections={sections}/>
        <Sections sections={sections} showSection={showSection}/>
        
        {/* {sections.map(section => {
            return (showSection.toLowerCase()==='all' || showSection.toLowerCase()===section.title.toLowerCase()) && <Section id={section.title} key={section.title} />
        })} */}
    </div>
}

export default Resume;
