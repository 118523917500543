import SectionList from "./SectionList";
import Employment from "./Employment";

const Sections = props =>{
    return props.sections.map(section => {
        let component = "";
        switch (section.title.toLowerCase()){
            case "employment":
                component = <Employment data={section.data}/>; break;
            default:
                component = <SectionList section={section.title} data={section.data} />; break;
        }
        component = <div key={section.title} id={section.title.toLowerCase() + 'Wrapper'}>
            <h3 className="sectionHeading" >{section.heading}</h3>
            {component}
        </div>
        
        return (props.showSection.toLowerCase()==='all' || props.showSection.toLowerCase()===section.title.toLowerCase()) && component;
    })
}

export default Sections;