import "./App.css";
import Baseball from "./components/Baseball";
import { Provider } from "react-redux";
import playersStore from "./store/players";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Resume from "./components/resume/Resume";

const router = createBrowserRouter([
  {path: "/", element: <Provider store={playersStore}><Baseball /></Provider>},
  {path: "resume", element: <Resume />}
])

function App() {
  return <RouterProvider router={router} />;
}

export default App;
