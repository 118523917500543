
var bFields = ["team","atBats","runs","hits","rbi","baseOnBalls","strikeOuts","stolenBases","doubles","triples","homeRuns","s_avg","s_homeRuns","s_runs","s_rbi","s_obp","s_slg","s_strikeOuts"];
var pFields=["team","inningsPitched","hits","runs","earnedRuns","baseOnBalls","strikeOuts","s_era","s_inningsPitched","s_strikeOuts","s_wins","s_saves","s_holds", "gameLog"];

const TableBody = (props) =>{
    const fields = props.bORp ==="b" ? bFields : pFields;
    const battingOrPitching = props.bORp ==="b" ? "batting" : "pitching";
    
    return props.players.map(player=>{
        return <tr key={player.person.id} data-team={player.team} className="playerRow">
            <td key={player.person.id + "-name"}><a href={`http://mlb.com/player/${player.person.id}`} target="_blank"> {player.person.fullName} </a></td>
            {fields.map(field=>{
                let value = null;
                if (field==="name" || field==="team"){
                    value = player[field];
                }else if (field==="gameLog"){
                    return <td key={`${player.person.id}-${field}`} field={field} className="sideLinkCell"><button data-player-id={player.person.id} onClick={props.gameLogClick}>Log</button></td>
                }else if (field.startsWith("s_")){		//SEASON STATS
                    value = player.seasonStats[battingOrPitching][field.slice(2)];		//USE substr TO IGNORE THE s_ PREFIX
                }else{
                    value = player.stats[battingOrPitching][field]; 
                } 
                return <td key={`${player.person.id}-${field}`} field={field}>{value}</td>
            })}
        </tr>
    })
}

export default TableBody;