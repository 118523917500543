
const Employment = props =>{
    return (props.data.map(job=>{
            return <div className="jobWrapper" key={job.id}>
                <div className="empSubHeading"><strong>{job.title}</strong> - {job.company}</div>
                <div className="empSubHeading empDates"><i>{job.start} - {job.end}</i></div>
                <div>{job.description}</div>
                <ul className="empList">
                    {job.highlights.map( (highlight, idx)=>{
                        return <li key={job.id + idx}>{highlight}</li>
                    })}
                </ul>
            </div>
        })
    )
    
}

export default Employment;