import TableBody from "./TableBody";
import TableHeadings from "./TableHeadings";
import { useSelector } from "react-redux";
import SideTable from "./SideTable";
import { useState, useRef, useEffect } from "react";

const bHeadings = ["Name","Team","AB","R", "H","RBI","BB","SO","SB","2B","3B","HR","AVG","HRs","Rs","RBIs","OBP","SLG","Ks"];
const pHeadings = ["Name","Team","IP","H", "R","ER","BB","SO","ERA","IPs","Ks","W","SV","HLD", "Logs"];

const NameSort = (a, b)=>{
    var a_name = a.name.split(" ");
    a_name = a_name[1] + a_name[0];	//REVERSE LASTNAME-FIRSTNAME
    
    var b_name = b.name.split(" ");
    b_name = b_name[1] + b_name[0];
    
    return (a_name < b_name ? -1: a_name > b_name ? 1: 0 );
}


const Table = (props) => {
    const playersData = useSelector(state=>state);
    const [expandPlayer, setExpandPlayer] = useState(null);
    const clickedSideLink = useRef(null);

    let playerList = playersData[props.id];
    if (props.showTeam != 'ALL') playerList = playerList.filter(player=> player.team.toLowerCase() == props.showTeam.toLowerCase());

    useEffect(()=>{
        setExpandPlayer(null);
        if (clickedSideLink.current !=null ){
            clickedSideLink.current.classList.remove('selectedSideLink');
        }
    },[props.showTeam])

    const expandPlayerClick = (e)=>{
        if (clickedSideLink.current !=null ){
            clickedSideLink.current.classList.remove('selectedSideLink');
            if (clickedSideLink.current == e.target){
                setExpandPlayer(null);
                clickedSideLink.current = null;
                return;
            }
        }
        e.target.classList.add('selectedSideLink');
        clickedSideLink.current = e.target;
        setExpandPlayer(parseInt(e.target.dataset.playerId));
    }

    return <>
        <table id={props.id} className='baseballTable' onClick={props.tableClick}>
            <TableHeadings headings={props.id == "batters" ? bHeadings: pHeadings} bORp={props.id == "batters" ? "b": "p"}/>

            <tbody id={props.id == "batters" ? "bBody" : "pBody"}> 
                <TableBody players={playerList} bORp={props.id == "batters" ? "b": "p"} gameLogClick={expandPlayerClick}/>
            </tbody>
        </table>
        {props.id === "pitchers" && expandPlayer && <SideTable playerID={expandPlayer} year={props.showDate.getFullYear()}/>}
    </>

};

export default Table;
